import { debounce } from '../../../../lib/utils/timing'
import { getCookie } from '../../../../lib/utils/cookies'

const SELECTOR_PLACEMENT = '.m-taboola__placement'
const LAZYLOAD_OFFSET = 600 // How many px in advance to load the placement

export default (() => {
  class Taboola {
    constructor({ selector }) {
      this.elems = [...document.querySelectorAll(selector)]
      if (!this.elems.length) {
        return
      }

      // As this is using page level config, we only need to load the script once
      // We can load it from any of the placements, so we'll load it from the first one for simplicity
      this._loadGlobalTaboolaScript(this.elems[0])

      this.lazyLoadPositions = new Map()
      this.elems.forEach(elem => {
        const config = this.getTaboolaConfig(elem)
        this.lazyLoadPositions.set(elem, config)
      })

      window.wafer.ready(() => {
        this._addEventListeners()
      })
    }

    getTaboolaConfig(elem) {
      const placementElem = elem.querySelector(SELECTOR_PLACEMENT)
      if (!placementElem) return {}

      const config = JSON.parse(placementElem.dataset.config)
      return {
        mode: config.mode,
        container: placementElem.id,
        placement: config.placement,
        target_type: config.targetType
      }
    }

    pushTaboolaConfig(config) {
      if (!config) return
      window._taboola = window._taboola || []
      _taboola.push(config)
    }

    flush() {
      // The flush flag instructs Taboola to fetch recommendations (and not wait for additional data).
      // API ref: https://developers.taboola.com/web-integrations/docs/js-tag#step-iii-add-the-flush-tag
      window._taboola = window._taboola || []
      _taboola.push({ flush: true })
    }

    _addEventListeners() {
      window.addEventListener('scroll', debounce(this._handleScroll.bind(this), 100))
      this._handleScroll()
    }

    _handleScroll() {
      const { elementInView } = window.wafer.utils
      this.lazyLoadPositions.forEach((config, elem) => {
        if (elementInView(elem, { offsetY: LAZYLOAD_OFFSET }, wafer.base.viewport)) {
          this.pushTaboolaConfig(config)
          this.flush()
          this.lazyLoadPositions.delete(elem)
        }
      })
    }

    _loadGlobalTaboolaScript(elem) {
      const config = JSON.parse(elem.dataset.config)
      const pblob = this._getPblob()
      const axid = this._getAXID()
      const taboolaPageConfig = {
        [config.pageType]: 'auto',
        ...(axid ? { unified_id: axid } : {}),
        ...(pblob ? { pblob } : {})
      }

      window._taboola = window._taboola || []
      _taboola.push(taboolaPageConfig)

      const firstScript = document.getElementsByTagName('script')[0]

      const script = document.createElement('script')
      script.src = `//cdn.taboola.com/libtrc/${config.publisherId}/loader.js`
      script.id = 'tb_loader_script'
      script.async = true

      firstScript.parentNode.insertBefore(script, firstScript)

      if (window.performance && typeof window.performance.mark === 'function') {
        window.performance.mark('tbl_ic')
      }
    }

    _getAXID() {
      // This get AXID logic is coppied from Benji here: https://git.ouryahoo.com/monetization/benji/blob/cb91e5ce4641b3b71ae1d6740d2fe9017aedbe42/src/helpers/cookie.ts#L44
      const COOKIE_NAME_AXID = 'axids'
      // Using the tbla AXID as requested here: https://ouryahoo.slack.com/archives/C06G0LSMZEV/p1708449617358289?thread_ts=1708039812.590369&cid=C06G0LSMZEV
      const GAM_AXID_KEY = 'tbla'
      const axids = getCookie(COOKIE_NAME_AXID) || ''
      const axidsSearchParams = new URLSearchParams(axids)
      return axidsSearchParams.get(GAM_AXID_KEY) || ''
    }

    _getPblob() {
      const GAM_CONFIG_NAMESPACE = '__GAM_CONFIG'
      const RS_KEYS = [
        'colo',
        'site',
        'region',
        'lang',
        'device',
        'ver',
        'pt',
        'pct',
        'lmsid',
        'pstaid',
        'lu',
        'axid'
      ]

      const gami3n = window[GAM_CONFIG_NAMESPACE]?.i13n || {}
      const axid = this._getAXID()

      const rsString = RS_KEYS.reduce((acc, key) => {
        if (key === 'axid' && axid) {
          acc.push(`${key}:${axid}`)
        } else if (gami3n[key]) {
          acc.push(`${key}:${gami3n[key]}`)
        }
        return acc
      }, []).join(';')

      const { spaceid = '', bucket = '' } = gami3n || {}

      /*
       * Pblob fields are defined in this ticket: https://ouryahoo.atlassian.net/browse/GM-12953
       * The fields are:
       *   1. RevShare
       *   2. GUID -- leave blank
       *   3. spaceid
       *   4. position -- leave blank
       *   5. requestId -- leave blank
       *   6. secure DARLA version-- leave blank
       *   7. Y-bucket
       *   8. Section ID -- leave blank
       */

      return [rsString, null, spaceid, null, null, null, bucket, null].join('|')
    }
  }

  return Taboola
})()
